


















































import { Component, Vue } from 'vue-property-decorator';
import { readOrgaos } from '@/store/orgao/getters';
import { dispatchDeleteOrgao, dispatchGetOrgaos } from '@/store/orgao/actions';
import { IOrgao } from '@/interfaces/orgao';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';

@Component({
  components: {
    ModalConfirmacao,
  },
})
export default class ListarOrgaos extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public orgaoExclusao;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'center',
    },
    {
      text: 'Nome',
      sortable: true,
      value: 'nome',
      align: 'left',
    },
    {
      text: 'Sigla',
      sortable: true,
      value: 'sigla',
      align: 'left',
    },
    {
      text: 'Ações',
      align: 'center',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  get orgaos() {
    return readOrgaos(this.$store);
  }

  public abrirModalExclusao(orgao: IOrgao) {
    this.orgaoExclusao = orgao;
    this.modalExclusaoAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteOrgao(this.$store, this.orgaoExclusao);
    this.modalExclusaoAtivo = false;
  }

  public async mounted() {
    await dispatchGetOrgaos(this.$store);
  }
}
